@import '../../base/pages/app-ecommerce';

.ecommerce-shop-pagination {
  .page-link {
    transition: all 0.05s ease-in-out;
  }
}

.product-checkout {
  .rc-input-number-handler-wrap {
    left: -0.35rem;
  }
}
