@import 'flow-dashboard';

body {
  padding-right: 0px !important;
}

.margin-small {
  margin: 0.5px;
}

.padding-small {
  padding: 0.5px;
}

.margin-medium {
  margin: 2px;
}

.margin-large {
  margin: 5px;
}

.mx-large {
  margin-left: 5px;
  margin-right: 5px;
}

.padding-small {
  margin: 0.5px;
}

.padding-medium {
  padding: 2px;
}

.padding-large {
  padding: 5px;
}

.headhunter {
  min-width: 40vw;
}

.resume-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 141.4285714%;
}

.resume {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swal2-html-container {
  overflow-y: hidden !important;
  height: auto !important;
}

.email-link {
  font-weight: 400;
}

.email-link:hover {
  color: #48D1CC;
  cursor: pointer;
  text-decoration: underline;
}

.text-link {
  font-weight: 600;
  color: #48D1CC !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.text-link-disabled {
  font-weight: 600;
  opacity: 0.5;
  color: #48D1CC !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.name-link {
  font-weight: 600;
}

.name-link:hover {
  color: #48D1CC;
  cursor: pointer;
  text-decoration: underline;
}

.popover {
  min-width: 500px;
  max-width: 550px;
 
  .popover-body {
    margin: 0px;
    padding: 0px;
  }

  .year-indicator {
    margin-left: 5px
  }

  .row {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
}

.skill-match-section {
  margin-bottom: 10px;
}

.skill-match-word {
  margin: 2.5px;
}

.skill-match-count {
  margin-left: 5px;
}

.text-left-important {
  text-align: left !important;
}

.icon-small {
  height: 17px;
}

.img-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.mail-popup {
  min-width: 50vw;

  .modal-body {
    min-height: 20vh;
  }
}

.mail-popup-sm {
  min-height: 0;
}

.mail-popup-lg {
  min-height: 45vh !important;
  max-height: 75vh !important;
}

.brand-logo img {
  max-width: 36px;
}

.application-form-logo {
  width: 40px;
  height: 40px;
}

.application-form-logo-text {
  font-weight: 700;
  font-size: 32px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.priority-creatable-button {
  margin: 2.5px;
  padding: 7.5px;
}

.priority-creatable-connector {
  margin: 0px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  font-size: 12px;
  font-weight: 700;
}
