.stage-node {
  .card {
    min-width: 250px;
    max-width: 250px;

    min-height: 150px;
    max-height: 150px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.diversity-node {
  .card {
    min-width: 200px;
    max-width: 200px;

    min-height: 250px;
    max-height: 250px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.conversion-node {
  .card {
    min-width: 450px;
    max-width: 450px;

    min-height: 250px;
    max-height: 250px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.duration-node {
  .card {
    min-width: 200px;
    max-width: 200px;

    min-height: 250px;
    max-height: 250px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.filter-node {
  .card {
    min-width: 675px;
    max-width: 675px;

    min-height: 350px;
    max-height: 350px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.activity-node {
  .card {
    min-width: 950px;
    max-width: 950px;

    min-height: 175px;
    max-height: 175px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}

.prediction-node {
  .card {
    min-width: 650px;
    max-width: 650px;

    min-height: 250px;
    max-height: 250px;

    .card-header {
      padding: 12.5px;
    }

    .card-body {
      padding: 15px;
    }
  }
}
